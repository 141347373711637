import React from 'react';
import PropTypes from 'prop-types';

const Item = ({ data }) => {
  return (
    <div className="col-15 col-sm-5 mb-30">
      <div className="features__feature">
        {
          data.svgIcon
            ? (
              <>
                <svg className="features__feature__image">
                  <use xlinkHref={data.svgIcon} />
                </svg>
                <svg className="features__feature__image-hover">
                  <use xlinkHref={data.hoverSvgIcon} />
                </svg>
              </>
            )
            : <i className={`${data.icon} features__feature__icon`} />
        }
        <h3 className="features__feature__title">{data.title}</h3>
        <p className="features__feature__description">{data.text}</p>
      </div>
    </div>
  );
};

Item.propTypes = {
  data: PropTypes.object,
};

export default Item;
