import React from 'react';
import _ from 'lodash';
import Link from 'next/link';
import PropTypes from 'prop-types';

import Item from './Item';

const WhyComponent = ({ classNameForBG }) => {
  const items = [
    {
      title: 'Piyasayı Yakından Takip Edin',
      text: 'Açık artırmadaki makinaları inceleyin, piyasayı en güncel haliyle takip edin.',
      icon: 'fa-regular fa-magnifying-glass-dollar',
    },
    {
      title: 'Ekspertiz Raporlu Makinaları Satın Alın',
      text: 'Ekspertiz raporunu inceleyin, kafanızda soru kalmadan güvenle teklif verin.',
      icon: 'fa-regular fa-file-check',
    },
    {
      title: 'Sürprizlerle Karşılaşmayın',
      text: 'Ciddiyetsiz satıcılar, kötü niyetli yaklaşımlar yerine profesyonel yöntemleri tercih edin.',
      icon: 'fa-regular fa-circle-exclamation',
    },
    {
      title: 'Detaylarla Uğraşmayın',
      text: 'Hukuki, Mali ve Operasyonel işlerle uğraşmayın.',
      svgIcon: '/image/icons.svg#ic-handshake',
      hoverSvgIcon: '/image/icons.svg#ic-handshake-yellow',
    },
    {
      title: 'Şeffaf, Hızlı ve Kolay Satın Alın',
      text: 'Kolayca teklif verin, sorunsuz bir şekilde makinayı satın alın.',
      svgIcon: '/image/icons.svg#ic-time',
      hoverSvgIcon: '/image/icons.svg#ic-time-yellow2',
    },
    {
      title: 'Güvenilir ve Kurumsal Yaklaşım',
      text: 'Makina alış sürecinizi profesyonelce yönetin.',
      svgIcon: '/image/icons.svg#ic-kurumsal',
      hoverSvgIcon: '/image/icons.svg#ic-kurumsal-yellow',
    },
    {
      title: 'Makinaların Gerçek Satış Fiyatlarını Görün',
      text: 'Makinaların gerçek satış değerlerini kendi gözlerinizle görün.',
      svgIcon: '/image/icons.svg#ic-piyasa-degeri',
      hoverSvgIcon: '/image/icons.svg#ic-piyasa-degeri-yellow',
    },
    {
      title: 'Deneyimli Ekip',
      text: 'İş Makinası sektöründe 45 yıllık tecrübemize güvenin.',
      svgIcon: '/image/icons.svg#ic-ekip',
      hoverSvgIcon: '/image/icons.svg#ic-ekip-yellow',
    },
  ];

  return (
    <section className={classNameForBG}>
      <h2 className="section__header__title section__header__title--mb-45">Neden iyiMakina.com?</h2>
      <div className="container">
        <div className="row row--mb30 features">
          {
            _.map(items, (item, index) => (
              <Item key={index} data={item} />
            ))
          }

          <div className="col-15 col-sm-5 mb-30">
            <a className="features__feature features__feature--space-between features__feature--p-30">
              <p className="features__feature__description fs-17">
                iyiMakina'daki satılık makinaları, yaklaşan açık artırmaları inceleyerek, kolayca teklif verebilirsiniz.
              </p>
              <Link href="/machinery">
                <button type="submit" className="im-button im-button--primary im-button--mobile-text-center">
                  <span>Satılık Makinaları İncele</span>
                  <i className="far fa-long-arrow-right im-button__icon--right" />
                </button>
              </Link>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

WhyComponent.propTypes = {
  classNameForBG: PropTypes.string,
};

export default WhyComponent;
